import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { getParameterByName } from './js/params';
import Login from './components/Auth/Login';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Disclaimer from './components/Disclaimer';
import Apps from './components/Apps';
import ScheduleFrame from './components/ScheduleFrame';
import FreeUtmAudit from './components/FreeUtmAudit/index';
import { Route, Routes, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setStoreData } from './store/auth';
import SignUp from './components/Auth/Signup';
import Layout from './components/Layout';
import PayFlow from './components/PayFlow';
import { useNavigate } from 'react-router-dom';
import AccountInfos from './components/AccountInfos';
import Home from './components/Home';
import ConfirmUser from './components/confirmUser';
import ForgotPassword from './components/ForgotPassword';
import Toast from './components/Toast';
import AreWeFit from './components/AreWeFit';
import Downloads from './components/AccountInfos/Downloads';
import Subscriptions from './components/AccountInfos/Subscriptions';
import Billings from './components/AccountInfos/Billings';
import MyAccounts from './components/AccountInfos/MyAccount/MyAccounts';
import Sites from './components/AccountInfos/Sites';
import NoMatch from './components/NoMatch';
import Chat from './components/Chat/Chat';
import SupportBought from './components/SupportBought';
import Welcome from './components/Welcome';
import UTM from './components/utm/Index';
import CampaignPromo from './components/PayFlow/CampaignPromo';
import Onboarding from './components/Onboarding';


function App() {
  const dispatch = useDispatch();
  const {
    isAuthenticated,
    isAuthenticating,
  } = useSelector((state) => state.auth.data || {});

  const navigate = useNavigate();

  const { $crisp } = window;

  const CrispChatActive = () => {
    if (typeof window.HandL === 'object') {
      let params = window.HandL.getAll();
      Object.keys(params).forEach((k) => params[k] == null && delete params[k]);
      let crispData = [];
      Object.entries(params).forEach(([key, value]) => {
        crispData.push([key, value]);
      });
      $crisp?.push(['set', 'session:data', [crispData]]);
    }
  };

  useEffect(() => {
    const startFunc = async () => {
      try {
        // const session = await Auth.currentSession();
        dispatch(setStoreData({ data: { isAuthenticated: true } }));
        // setMainStates("isAuthenticated", true);
        if (localStorage.getItem('paidMethod')) {
          dispatch(
            setStoreData({
              data: {
                isSupportable: JSON.parse(
                  localStorage.getItem('isSupportable')
                ),
              },
            })
          );
          // setMainStates("isSupportable", localStorage.getItem("isSupportable"));
        }
        const user = await Auth.currentAuthenticatedUser();
        console.log('current authenticated: ', user);
        dispatch(
          setStoreData({
            data: {
              user,
              tmpUser: {
                first_name: user.attributes.given_name,
                email: user.attributes.email,
              },
            },
          })
        );

          $crisp?.push(['set', 'user:nickname', [user.attributes.given_name]]);
          $crisp?.push(['set', 'user:email', [user.attributes.email]]);
      } catch (error) {
        let tmpUser = localStorage.getItem('tmpUser');
        if (tmpUser) {
          dispatch(
            setStoreData({
              data: {
                tmpUser: JSON.parse(tmpUser),
                isAuthenticated: true,
              },
            })
          );
        } else {
          dispatch(
            setStoreData({
              data: {
                isAuthenticated: false,
              },
            })
          );
        }
        console.log('error: ', error);
      }

      if (getParameterByName('login') === '1' && !isAuthenticated) {
        dispatch(
          setStoreData({
            data: {
              showLogin: true,
            },
          })
        );
      }
      if (getParameterByName('forgotPassword') === '1') {
        dispatch(
          setStoreData({
            data: {
              showForgotPassword: true,
            },
          })
        );
      }

      if (getParameterByName('thanks') === '1') {
        navigate('/payment' + window.location.search);
      }

      // if ( getParameterByName('code') && getParameterByName('scope')){
      if (getParameterByName('scope')) {
        dispatch(
          setStoreData({
            data: {
              showApps: true,
            },
          })
        );
      }
      dispatch(
        setStoreData({
          data: {
            isAuthenticating: false,
          },
        })
      );

      $crisp?.push(['on', 'chat:initiated', CrispChatActive]);
      $crisp?.push(['on', 'message:sent', CrispChatActive]);
    };
    startFunc();
  }, []);

  return (
    <>
      <Login />
      <SignUp />
      <AreWeFit />
      <ForgotPassword />
      <CampaignPromo />
      <Toast />
      {getParameterByName('confirmation_code') !== '' && <ConfirmUser />}
      {!isAuthenticating && (
        <Routes>
          {/* <Switch> */}
          <Route path='/' element={<Home />} />
          <Route path='terms' element={<Terms />} />
          <Route path='privacy' element={<Privacy />} />
          <Route path='disclaimer' element={<Disclaimer />} />
          <Route path='payment' element={<PayFlow />} />
          <Route path='support/welcome' element={<SupportBought />} />
          <Route path='welcome' element={<Welcome />} />
          <Route path='w/onboarding/*' element={<Onboarding />} />
          <Route path='infos' element={<AccountInfos />}>
            <Route index element={<Downloads />} />
            <Route path='downloads' element={<Downloads />} />
            <Route path='subscriptions' element={<Subscriptions />} />
            <Route path='invoices' element={<Billings />} />
            <Route path='billings' element={<MyAccounts />} />
            <Route path='sites' element={<Sites />} />
            <Route path='chat' element={<Chat />} />
            <Route path='*' element={<NoMatch />} />
          </Route>
          <Route path='schedule' element={<ScheduleFrame />} />
          <Route path='utm' element={<UTM />} />
          <Route path='free-utm-audit' element={<FreeUtmAudit />} />
          <Route path='apps' element={<Apps />} />
          <Route path='*' element={<NoMatch />} />
          {/* </Switch> */}
        </Routes>
      )}
    </>
  );
}

export default App;
