import React, { useState, useEffect, useRef } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { setStoreData } from '../../store/auth';
import { useLazyGetGeoQuery, useOnChargeMutation } from "../../features/payment/paymentAPI"
import { useSearchParams } from 'react-router-dom';
// const { gtag, fbq, rdt, HandL, data, twq, twttr } = window;

export default function CheckoutForm({ setCurrentStep, setSelectedMethod, coupon,resetActiveCoupon}) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('US');
  const [region, setRegion] = useState('');
  const [postal, setPostal] = useState('');
  const [customer, setCustomer] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message, className) => (
        <div className={className}>{message}</div>
      ),
    })
  );
  const dispatch = useDispatch();
  const [onPay] = useOnChargeMutation();
  const {
    quantity,
    product_price,
    tmpUser,
    stripe_plan_id,
    isSupportable,
    prices,
    multiplier,
    membership,
  } = useSelector((state) => state.auth.data || {});

  const [getGeo] = useLazyGetGeoQuery();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    getGeo().unwrap().then((data) => {
      setRegion(data.region);
      setCountry(data.countryCode);
      setPostal(data.zip);
    }).catch((e) => console.error(e));

    $('#payment-form').find('input')[0].focus();

    if (membership) createCustomer(0);
    else getClientSecret(0);
  }, []);

  function beginCheckoutGA() {
    window.gtag('event', 'begin_checkout', {
      items: [
        {
          id: 'HandL002',
          name: 'HandL UTM Grabber V3',
          brand: 'HandL',
          category: 'WordPress Plugins',
          variant: 'V3',
          list_position: 2,
          quantity: `${quantity}`,
          price: `${product_price}.00`,
        },
      ],
      coupon: coupon,
      email: tmpUser.email,
    });

    window.fbq('track', 'InitiateCheckout', {
      currency: 'USD',
      value: `${product_price}.00`,
    });

    window.rdt('track', 'AddToCart', {
      currency: 'USD',
      itemCount: `${quantity}`,
      value: `${product_price}.00`,
    });
  }

  const getClientSecret = async (tries) => {
    console.log('Tries: ' + tries);
    if (tries <= 3) {
      try {
        const data = await onPay({
          pay_id: 'stripe',
          coupon,
          quantity,
          marketing: window.HandL.getAll(),
        }).unwrap();
        console.log('getClientSecret', data);
        setClientSecret(data.clientSecret.client_secret);
        beginCheckoutGA();
      } catch {
        // console.log(data);
        // console.log(typeof data);
        setTimeout(function () {
          getClientSecret(tries + 1);
        }, 500);
      }
    }
  };

  const createCustomer = async (tries) => {
    console.log('Tries: ' + tries);
    if (tries <= 3) {
      try {
        const result = await onPay({
          pay_id: 'stripe',
          membership: true,
          type: 'cus',
          email: tmpUser.email,
          name: tmpUser.first_name,
          marketing: window.HandL.getAll(),
        }).unwrap();
        console.log(createCustomer, result);
        setCustomer(result.customer);
        beginCheckoutGA();
      } catch (err) {
        console.log(err);
        setTimeout(function () {
          createCustomer(tries + 1);
        }, 500);
      }
    }
  };

  const cardStyle = {
    style: {
      base: {
        fontFamily: '"Poppins",sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '17px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  async function createSubscription({ customerId, paymentMethodId, quantity, address }) {
    console.log({ customerId, paymentMethodId, quantity });
    const mainPriceId = stripe_plan_id;
    const supportPriceId = prices.support.stripe;
    localStorage.setItem('paidMethod', 'stripe');
    try {
      const response = await onPay({
        pay_id: 'stripe',
        membership: true,
        type: 'sub',
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        quantity: quantity,
        coupon,
        mainPriceId: mainPriceId,
        multiplier: multiplier ? 2 : 1,
        marketing: window.HandL.getAll(),
        address: address,
        ...isSupportable && {
          supportPriceId: supportPriceId,
        }
      }).unwrap();
      const result = response.mainSubscription;
      console.log('First Result');
      console.log(result);
      handlePaymentThatRequiresCustomerAction({
        paymentMethodId: paymentMethodId,
        quantity: quantity,
        subscription: result,
        subscription_status: result.status,
      });
      handleRequiresPaymentMethod({
        paymentMethodId: paymentMethodId,
        quantity: quantity,
        subscription: result,
        subscription_status: result.status,
      });
      onSubscriptionComplete({
        paymentMethodId: paymentMethodId,
        quantity: quantity,
        subscription: result,
        subscription_status: result.status,
      });
    } catch (error) {
      console.log(error);
      if (error === "This promotion code is not specified for use with this customer."){
        resetActiveCoupon();
      }
      setProcessing(false);
      handleChange({ error: { message: error?.message ?? error } });
    }
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    quantity,
    paymentMethodId,
    isRetry,
    subscription_status,
  }) {
    console.log('handlePaymentThatRequiresCustomerAction');
    console.log({
      subscription,
      invoice,
      quantity,
      paymentMethodId,
      isRetry,
      subscription_status,
    });
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, quantity, paymentMethodId, subscription_status };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          console.log(result);
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                subscription_status: 'active',
                quantity,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    } else {
      // No customer action needed.
      return { subscription, quantity, paymentMethodId, subscription_status };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    quantity,
    subscription_status,
  }) {
    console.log('handleRequiresPaymentMethod');
    console.log({
      subscription,
      paymentMethodId,
      quantity,
      subscription_status,
    });
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, quantity, paymentMethodId, subscription_status };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      // localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      // localStorage.setItem(
      //     'latestInvoicePaymentIntentStatus',
      //     subscription.latest_invoice.payment_intent.status
      // );
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, quantity, paymentMethodId, subscription_status };
    }
  }

  function onSubscriptionComplete(result) {
    console.log('onSubscriptionComplete');
    console.log(result);
    // Payment was successful.
    if (result.subscription_status === 'active') {
      console.log(result);
      console.log('Charge successfull!');
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      paymentSuccessful(result);
    }
  }

  function paymentSuccessful(payload) {
    setError(null);
    setProcessing(false);
    setSucceeded(true);
    setSelectedMethod('cc');
    setCurrentStep(2);
    setSearchParams({
      pay_id: 'stripe',
      subscription_id: payload.subscription.id,
      thanks: 1,
    });
    let amount, txid;

    if (payload.paymentIntent !== undefined) {
      amount = payload.paymentIntent.amount;
      txid = payload.paymentIntent.id;
    } else if (payload.subscription !== undefined) {
      amount = payload.subscription.latest_invoice.amount_paid;
      txid = payload.subscription.latest_invoice.charge;
    } else {
      //hope never goes here :)
      amount = 1;
      txid = 'Not Captured';
    }

    window.gtag('event', 'purchase', {
      transaction_id: txid,
      value: amount / 100,
      currency: 'USD',
      tax: 0.0,
      shipping: 0,
      items: [
        {
          id: 'HandL002',
          name: 'HandL UTM Grabber V3',
          brand: 'HandL',
          category: 'WordPress Plugins',
          variant: 'V3',
          list_position: 2,
          quantity,
          price: amount / 100,
        },
      ],
      email: tmpUser.email,
    });

    window.gtag('event', 'conversion', {
      send_to: 'AW-604754791/ySYVCOmm8NoBEOemr6AC',
      transaction_id: txid,
      value: amount / 100,
      currency: 'USD',
    });

    window.fbq('track', 'Purchase', { currency: 'USD', value: amount / 100 });

    window.twq('event', 'tw-nytpn-onjy3', {
      value: amount / 100,
      currency: 'USD',
      conversion_id: txid,
      email: tmpUser.email,
      // phone_number: tmpUser.phone_number,
    });

    window.rdt('track', 'Purchase', {
      currency: 'USD',
      itemCount: 1,
      value: amount / 100,
    });

    //window.location = addQString('thanks', 1)
  }

  const retryInvoiceWithNewPaymentMethod = async ({
    customerId,
    paymentMethodId,
    invoiceId,
    quantity,
    address
  }) => {
    console.log('retryInvoiceWithNewPaymentMethod');
    console.log({ customerId, paymentMethodId, invoiceId, quantity });
    return (
      onPay({
        pay_id: 'stripe',
        membership: true,
        type: 'retry',
        customerId,
        paymentMethodId,
        invoiceid: invoiceId,
        quantity,
        marketing: window.HandL?.getAll(),
        address: address,
      }).unwrap()
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            isRetry: true,
            quantity: quantity,
            subscription_status: result.status,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setProcessing(false);
          handleChange(error);
        })
    );
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (validator.current.allValid()) {
      setProcessing(true);

      const cardElement = elements.getElement(CardElement);
      let payload, latestInvoicePaymentIntentStatus;
      const addressInfo = {
        line1: address,
        postal_code: postal,
        state: region,
        country: country,
      }
      if (membership) {
        latestInvoicePaymentIntentStatus = localStorage.getItem(
          'latestInvoicePaymentIntentStatus'
        );

        payload = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: tmpUser.first_name,
            email: tmpUser.email,
            address: addressInfo,
          },
        });
      } else {
        payload = await stripe.confirmCardPayment(clientSecret, {
          // return_url: addQString('thanks',1),
          payment_method: {
            card: cardElement,
            billing_details: {
              name: tmpUser.first_name,
              email: tmpUser.email,
              address: addressInfo,
            },
          },
        });
      }

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setSelectedMethod('cc');
        if (membership) {
          console.log('[PaymentMethod]', payload.paymentMethod);
          const paymentMethodId = payload.paymentMethod.id;
          const customerId = customer.id;

          if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
            // Update the payment method and retry invoice payment
            const invoiceId = localStorage.getItem('latestInvoiceId');
            retryInvoiceWithNewPaymentMethod({
              customerId,
              paymentMethodId,
              invoiceId,
              quantity,
              address: addressInfo,
            });
          } else {
            // Create the subscription
            createSubscription({
              customerId, paymentMethodId, quantity, address: addressInfo
            });
          }
        } else {
          paymentSuccessful(payload);
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <div className='flex space-x-3'>
        <div className='form-group w-full'>
          <input
            type='text'
            name='address'
            className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            placeholder='Street Address'
            onChange={(val) => setAddress(val.target.value)}
          />
          {validator.current.message('Address', address, 'required', {
            className: 'text-danger',
          })}
        </div>
      </div>
      {/* <div className='flex space-x-3'>
      </div> */}
      <div className='md:flex md:space-x-3'>
        <div className='form-group'>
          <CountryDropdown
            valueType='short'
            classes='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            value={country}
            onChange={(val) => setCountry(val)}
          />
        </div>
        <div className='form-group w-full'>
          <RegionDropdown
            classes='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            country={country}
            value={region}
            defaultOptionLabel='Select State'
            valueType='short'
            countryValueType='short'
            onChange={(val) => setRegion(val)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            name='postal'
            className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            placeholder='Zip Code'
            value={postal}
            onChange={(val) => setPostal(val.target.value)}
          />
          {validator.current.message('Zip Code', postal, 'required', {
            className: 'text-danger',
          })}
        </div>
      </div>
      <div className='form-group w-full'>
        <CardElement
          className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
          id='card-number'
          options={cardStyle}
          onChange={handleChange}
        />
      </div>

      <div className='flex justify-end'>
        <div className="submit-area-2 row m0 flex-1">
          <button
            disabled={processing || disabled || succeeded}
            className={`btn btn-rounded btn-block w-full disabled:cursor-not-allowed disabled:!opacity-70 ${processing ? " active" : ""}`}
          >
            <span id="button-text">
              {processing ? (
                <span>
                  <i className="fa fa-spin fa-spinner"></i> Processing...
                </span>
              ) : (
                "Pay Now"
              )}
            </span>
          </button>
        </div>
        {/* <button
          disabled={processing || disabled || succeeded}
          className={`btn btn-rounded btn-block handlPayButton ${
            processing ? ' active' : ''
          }`}
        >
          <span id='button-text'>
            {processing ? (
              <span>
                <i className='fa fa-spin fa-spinner'></i> Processing...
              </span>
            ) : (
              'Pay Now'
            )}
          </span>
        </button> */}
      </div>

      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className='w-full bg-red-100 border border-red-500 text-red-700 px-3 py-2 rounded relative mx-auto mt-2 font-medium text-center' role='alert'>
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? 'result-message' : 'result-message hidden'}>
        Your payment succeeded
      </p>
    </form>
  );
}
