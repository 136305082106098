import React from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "../css/ReactToastify.css";
function Toast() {
  const init = () => {
    // Check if initialized before
    if (localStorage.getItem("reviewToastsSeenAlready") === null) {
      localStorage.setItem("reviewToastsSeenAlready", JSON.stringify([]));
    }
    const interVal = setInterval(() => {
      const seenToasts = JSON.parse(localStorage.getItem("reviewToastsSeenAlready"));
      const unSeenMessages = messages.filter(
        (e) => !seenToasts.includes(e.title)
      );
      if (unSeenMessages.length === 0) {
        clearInterval(interVal);
        return;
      }
      // Pick a random message
      const randomMessage =
        unSeenMessages[Math.floor(Math.random() * unSeenMessages.length)];
      showToast(randomMessage);
      localStorage.setItem(
        "reviewToastsSeenAlready",
        JSON.stringify([...seenToasts, randomMessage.title])
      );
    }, 25000);
    return interVal;
  };
  const showToast = (message) => {
    return toast.success(
      <div>
        <div>
          {message.title} <br />
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
        </div>
        <div className="flex">
          <img
            className="img-circle img-thumbnail w-[100px] h-[100px]"
            src={message.src}
          />{" "}
          <div className="pl-2">
            {message.name}: {message.msg}
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    const toastInterval = init();
    return () => clearInterval(toastInterval);
  }, []);
  useEffect(() => {}, []);
  return (
    <ToastContainer
      className={"z-[1000] max-w-[500px] w-screen"}
      limit={1}
      position="bottom-left"
      autoClose={10000}
      hideProgressBar={true}
      theme="colored"
    />
  );
}

const messages = [
  {
    src: "https://secure.gravatar.com/avatar/94c8f57e5d960ec60ae6832351b484d6?s=60&d=retro&r=g",
    title: "Fantastic Plugin",
    name: "@Peter Geisheker",
    msg: "I had been searching everywhere to find a WordPress plugin that would grab UTM info and put it into my contact form so I could track the source of each lead. This plugin did it perfectly. I highly recommend it.",
  },
  {
    src: "https://secure.gravatar.com/avatar/2b84a4de91dd5178c70e80494c67394b?s=60&d=retro&r=g",
    title: "Excellent product backed by A+ support",
    name: "@mhpurvis",
    msg: "This product has allowed us to track our marketing efforts better than any other we've tried - and their support is awesome! They helped us through some complicated integrations. Hands own the best support we've received from any other WordPress plugin team! Thank you!",
  },
  {
    src: "https://secure.gravatar.com/avatar/f7f769504040d66a9393e85aea189a32?s=60&d=retro&r=g",
    title: "Fabulous plugin and support",
    name: "@malekavrana",
    msg: "I can't recommend this plugin more. I use FlyWheel hosting which was striping the utm codes. I tried everything. Should have tried the plugin first. I purchased the starter plan. Super easy to set up and had the chat support help with a few items. HIGHLY RECCOMEND",
  },
  {
    src: "https://www.gravatar.com/avatar/00000000000000000000000000000000?s=60&d=mp&r=g",
    title: "A great plugin with amazing support!",
    name: "@dmandan5",
    msg: "This is a great little plugin and the support is incredible. Haktan was super responsive to the questions I had and set everything up for us within a day!",
  },
  {
    src: "https://secure.gravatar.com/avatar/148bf732cc1fbd352847fd945de7ab47?s=60&d=retro&r=g",
    title: "The best plugin with excellent support",
    name: "@sak86",
    msg: "This plugin can be integrated with any form and even some custom ways. Also the support i experienced was outstanding!",
  },
  {
    src: "https://secure.gravatar.com/avatar/14d37057870a9cc1d9d037aaa1547f5a?s=60&d=retro&r=g",
    title: "Very useful plugin !!!",
    name: "@senseart",
    msg: "Very useful plugin and super fast support. I am extremely pleased with the developer.",
  },
];
export default Toast;