import { createApi } from '@reduxjs/toolkit/query/react';
import { amplifyBaseQuery } from '../../store/utils';

export const myAccountAPI = createApi({
    reducerPath: 'myAccountAPI',
    baseQuery: amplifyBaseQuery,
    tagTypes: ['MyAccount'],
    endpoints: (builder) => ({
        changeEmail: builder.mutation({
            query: (data) => ({
                url: '/users/change_email',
                method: 'POST',
                useAuth: true,
                data: {
                    code: data.code,
                    accessToken: data.accessToken,
                },
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            }
        }),
        forgotPassword: builder.mutation({
            query: (data) => ({
                url: '/users/forgot_password',
                method: 'POST',
                useAuth:false,
                data: {
                    email: data.email,
                },
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            }
        }),
        resendVerification: builder.mutation({
            query: (data) => ({
                url: '/users/verify_email',
                method: 'POST',
                useAuth:false,
                data: {
                    email: data.email,
                },
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            }
        }),
    }),
});

export const {
    useChangeEmailMutation,
    useForgotPasswordMutation,
    useResendVerificationMutation,

} = myAccountAPI;
