import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyGetPlansQuery } from '../../features/plan/planAPI';
import NetworkErrorScreen from '../Error/NetworkErrorScreen';
import utmIcon from '../../images/handl-utm-grabber-g.png'
function LicensePlans({ plan }) {
  console.log('plan: ', plan);
  let domainCount = plan?.domains?.filter(e => e != '').length ?? 0;
  return (
    <div className='border border-gray-200 mb-2 p-3 rounded-md max-w-md'>
      <p className=''>
        {domainCount} / {plan.license_number} : <span className={(plan.subscription_status.toLowerCase() == 'canceled' || plan.subscription_status.toLowerCase() == 'cancelled') ? 'line-through' : ''}>{plan.license_key}</span>{' '}
        {(plan.subscription_status.toLowerCase() == 'canceled' || plan.subscription_status.toLowerCase() == 'cancelled')
          ? '(Canceled)'
          : ''}
      </p>
      <ul className='max-w-md divide-y divide-gray-200'>

        {domainCount ? plan.domains.filter(e => e != '').map((domain) => {
          return <LicensePlan domain={domain} key={domain} />;
        }) : <LicensePlan domain={'No available domains.'} />}
      </ul>
    </div>
  );
}

function LicensePlan({ domain }) {
  return (
    <li className='pb-2 pt-2' key={domain}>
      <div className='flex items-center space-x-4'>
        <div className='flex-shrink-0'>
          <img
            className='w-8'
            src={utmIcon}
          />
        </div>
        <div className='flex-1 min-w-0'>
          <p className='text-sm font-medium text-gray-900 truncate'>{''}</p>
        </div>
        <div className='inline-flex items-center text-base font-semibold text-gray-900'>
          {domain}
        </div>
      </div>
    </li>
  );
}

export default function Sites() {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector(
    (state) => state.auth.data || {}
  );
  const [getPlans, {
    data: plans = {
      subscriptions: [],
    },
    isLoading: plansLoading,
    isError: plansError,
  }] = useLazyGetPlansQuery();
  useEffect(() => {
    if (isAuthenticated && user) {
      getPlans(null, true);
    } else if (!isAuthenticated) {
      navigate('/');
    }
  }, []);
  if (plansError) return <NetworkErrorScreen onRetry={() => getPlans()} loading={plansLoading} />;
  if (plansLoading) {
    return (
      <div className='flex justify-center'>
        <div className='fa-3x'>
          <i className='fa fa-spinner fa-spin'></i>
        </div>
      </div>
    );
  }

  if (!plans.subscriptions || plans.subscriptions.filter(e => !Boolean(e.addon_id)).length == 0) {
    return <div>No sites to display.</div>;
  }

  return plans.subscriptions.map((plan) => {
    if (plan.addon_id) {
      return null;
    }
    return <LicensePlans plan={plan} key={plan.license_key} />
  });
}
